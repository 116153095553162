<template>
  <div>
    <div>
      <h5 class="text-center plan-group-title">{{ skus.name }}</h5>
    </div>

    <div
      class="price-item-border shadow-sm"
      v-for="(item, i) in skus.items"
      :key="i"
      @click="selectSku(item)"
    >
      <div class="row">
        <div class="col-3 d-flex align-items-center">
          <div class="gift-icon-bg" :class="background">
            <span class="icon gift"></span>
          </div>
        </div>
        <div class="col-6 p-0">
          <div class="rate-container">
            <div>
              <div class="rate-title">
                {{ item.skuName }}
              </div>
              <div class="rate-description">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 d-flex align-items-center p-2">
          <div class="price-item" :class="background">
            <div>
              <div class="currency">PHP</div>
              <div class="price">
                {{ item.price.split(".")[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkuItem",
  // data() {
  //   return {
  //     selectedSku: "",
  //   };
  // },
  props: {
    // skuTitle: {
    //   type: String,
    // },

    skus: {
      type: Object,
      default() {
        return {};
      },
    },

    background: {
      type: String,
    },
  },

  methods: {
    selectSku(data) {
      let sku = {
        name: "",
        items: [],
        background: this.$props.background,
      };
      sku.items.push(data);
      this.$store
        .dispatch("ADD_SKU", sku)
        .then(() => {
          this.$router.push({ path: "/checkout" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    // selectedSku() {
    //   const isEmpty = Object.keys(this.$store.getters.GET_SKU).length === 0;
    //   if (!isEmpty) {
    //     return this.$store.getters.GET_SKU.items[0].skuId;
    //   }
    //   return 0;
    // },
  },
};
</script>
